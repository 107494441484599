import { Fragment, useState, useEffect } from 'react'
import { IonPage, useIonModal, useIonToast } from '@ionic/react'
import Toast from '../utils/Toast'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useAuth } from '../auth'
import SearchField from '../components/SearchField'
import GoogleMap from '../components/GoogleMap'
import { useTowers, useToggleTowers } from '../data/tower'
import { useUser } from '../data/user'
import { HOME_PATH, SETTINGS_UPGRADE_PLAN_PATH } from '../path'
import { get_pin_color } from '../utils/googleMap'
import UpgradePopup from '../components/UpgradePopup'
import { useLogFeatureInfo } from '../utils/session'

const TOWER_HEIGHT = 230
const TOWER_HEIGHT_PX = `${TOWER_HEIGHT}px`

const Container = styled.div`
	display: grid;
	grid-template-areas: 'list map';
	grid-template-columns: 380px 1fr;
	grid-template-rows: 100vh;
	grid-template-rows: -webkit-fill-available;

	& .tower-list-mobile {
		display:none;
	}

	& .metaText {
		z-index: 130; 
		position: absolute;
		left: env(keyboard-inset-height, 15px);
	}

	& .tower-item {
		display: grid;
		grid-template-areas:
			'name 		label'
			'details	switch';
		grid-template-columns: 1fr 50px;
		width: 100%;
		padding: 16px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		
		&.disabled {
			& .tower-name, .tower-map-label, .tower-details {
				opacity:0.5;
			}
			
			& .tower-map-label div {
				background-color: #919191;
				border: 1px solid #515151;
			}
		}

		& .toggle {
			grid-area: switch;
			display: flex;
			align-items: end;
			justify-content: center;
			height: 100%;
			margin-top: 7px;
		}

		& .tower-name {
			grid-area: name;
			padding: 0px 0px 2px 8px;
			
			display: flex;

			& .t-name  {
				font-weight: bold;	
			}

			& .with-24h {
				border: 1px solid #00a5ff;
				width: 54px;
				height: 20px;
				border-radius: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 500;
				color: white;
				background-color: #00a5ff;
				margin-top: 2px;
				margin-left: 7px;
			}
		}

		& .tower-map-label {
			grid-area: label;
			display: flex;
			justify-content: center;

			& div {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 36px;
				height: 36px;
				background-color: var(--pin-color, #e04633);
				border: 1px solid var(--pin-border-color, #B8372C);
				border-radius: 34px;
				color: white;
			}
		}

		& .tower-details {
			grid-area: details;
		}

		& .tower-detail {
			padding-left: 8px;
		}

		& .tower-default {
			margin-left: 8px;
			margin-top: 16px;
		}
	}

	& .tower-list-desktop {
		grid-area: list;
		box-shadow: rgb(0 0 0 / 35%) 0px 8px 15px;
		z-index: 1;
		border-right: 1px solid rgba(0, 0, 0, 0.12);

		& .search-input {
			padding: 8px 8px 16px 8px;

			& .legend {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 28px 24px;
				margin-top: 18px;

				& .legend-item {
					display: flex;
					justify-content: center;

					& .legend-container {
						width: 140px;
						display: flex;
						justify-content: start;
					}
				}

				& .swatch {
					width: 18px;
					min-width: 18px;
					height: 18px;
					border-radius: 18px;
					background-color: var(--pin-color,#e04633);
					border: 1px solid var(--pin-border-color,#B8372C);
					margin-right: 10px;
				}

				& .swatch-label {
					white-space: nowrap;
					-webkit-user-select: none; /* Safari */
					-ms-user-select: none; /* IE 10 and IE 11 */
					user-select: none; /* Standard syntax */
				}
			}
		}

		& .towers-loading {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 30px;
		}

		& .tower-items {
			overflow-y: scroll;
			height: 100vh;
		}

	}

	& .googlep-map {
		grid-area: map;	
	}

	& .fab-btn {
		position:absolute;
		bottom:16px; 
		right: unset;
		left:16px;
	}

	@media (max-width: 768px) {
		grid-template-areas: 'map';
		grid-template-columns: 1fr;

		& .tower-list-desktop {
			display: none;
		}

		& .tower-list-mobile {
			display: block;
			position: absolute;
			width: 100%;
			height: calc(100% - 215px);

			&.short-cards {
				height: calc(100% - 175px);				
			}

			& .search-input {
				position: relative;
				padding: 12px;
				z-index: 20;

				& .legend {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 28px 24px;
					margin-top: 18px;
					background-color: #0000006e;
					height: 75px;
					align-items: center;
					padding: 10px;
					border-radius: 20px;
					backdrop-filter: blur(3px);

					& .legend-item {
						display: flex;
						display: flex;
						justify-content: center;

						& .legend-container {
							width: 140px;
							display: flex;
							justify-content: start;
						}
					}

					& .swatch {
						width: 18px;
						min-width: 18px;
						height: 18px;
						border-radius: 18px;
						background-color: var(--pin-color,#e04633);
						border: 1px solid var(--pin-border-color,#B8372C);
						margin-right: 10px;
					}

					& .swatch-label {
						white-space: nowrap;
						color: white;
						-webkit-user-select: none; /* Safari */
						-ms-user-select: none; /* IE 10 and IE 11 */
						user-select: none; /* Standard syntax */
					}
				}
			}

			& .tower-items {
				overflow-x: scroll;
				width: 100%;
				position: fixed;
				bottom: calc(0px + var(--ios-keyboard-offset, 0px));
				transition: bottom 200ms ease-out;
				z-index: 20;
			}

			& .tower-left-gutter {
				margin-left: 8px;
			}

			& .tower-card {
				cursor: pointer;
				min-width: calc(100vw - 24px);
				width: calc(100vw - 24px);
				height: 185px;
				background-color: white;
				margin: 3px;
				border-radius: 12px;
				border: 1px solid #0000001a;
				box-shadow: rgb(0 0 0 / 35%) 0px 8px 15px;

				&:hover {
					background-color: #e7e7e7;
				}

				&.short-card {
					height: 145px;
				}
			}
		}

		& .fab-btn {
			bottom: 55px;
			right: 31px;
			left: unset;
		}
	}
`

/**
 * Filters towers 
 * 
 * @param	{[Tower]}	towers[]	
 * @param	{Object}		searchCriteria
 * @param	{String}			.text	
 * @param	{Boolean}		.exact	
 * 
 * @return {[Tower]}	filteredTowers
 */
const filterTowers = (towers:any, searchCriteria:any) => {
	const text = (searchCriteria?.text||'').toLowerCase().trim()
	const exact = searchCriteria?.exact

	if (text) {
		const filterFn = exact 
			? (name:string, serial:string) => name == text || serial == text
			: (name:string, serial:string) => name.indexOf(text) >= 0 || serial.indexOf(text) >= 0

		return (towers||[]).filter((t:any) => {
			const towerName = (t?.name||'').toLowerCase().trim()
			const serial = (t?.serial_no||'').toLowerCase().trim()
			return filterFn(towerName, serial)
		})
	} else
		return towers||[]
}

const get_tower_status = (latest_reading?:any, online?:any) => {
	if (!online)
		return 'offline'
	else if (latest_reading?.hazard !== null)
		return latest_reading?.hazardous_status
	else
		return 'absent'
}

const TowerDetails = ({ ...props }) => {
	const { tower, on_click, freeze_toggle_tower, show_toggle_switch  } = props
	const color = get_pin_color(get_tower_status(tower?.latest_reading, tower?.online))
	const dark_color = get_pin_color(get_tower_status(tower?.latest_reading, tower?.online), true)
	const [reset_counter, set_reset_counter] = useState(0)
	const [checked, set_checked] = useState(tower?.access_to_24h)

	const color_style = {
		'--pin-color': color,
		'--pin-border-color': dark_color
	} as React.CSSProperties

	const root_classes = ['tower-item']
	if (tower.disabled)
		root_classes.push('disabled')

	const reset = () => set_reset_counter(reset_counter+1)

	const select_tower = () => {
		if (tower.disabled && props.show_toggle_towers)
			props.show_toggle_towers(reset)
		else
			on_click()
	}

	const toggle_tower = (toggle:any) => {
		if (props.toggle_tower)
			props.toggle_tower(tower.id, toggle, set_checked)
	}

	return (<div className={root_classes.join(' ')}>
		<Typography component="div" variant="body1" className="tower-name" onClick={select_tower}>
			<div className="t-name">{tower?.name}</div>{!tower?.access_to_24h ? null : 
			<div className="with-24h">24H</div>}
		</Typography>
		<div className="tower-map-label" style={color_style} onClick={select_tower}>
			<div><Typography variant="body2">{tower?.map_label}</Typography></div>
		</div>
		<div className="tower-details" onClick={select_tower}>
			{tower?.distance !== null && <Typography variant="body2" color="text.secondary" className="tower-detail">{`Distance · ${tower?.distance.toFixed(2)} km`}</Typography>}
			{tower?.direction && <Typography variant="body2" color="text.secondary" className="tower-detail">{`Direction · ${tower?.direction}`}</Typography>}
			{tower?.coord && <Typography variant="body2" color="text.secondary" className="tower-detail">{`Lat/Lon · ${tower?.coord.lat} ${tower?.coord.long}`}</Typography>}
			{tower?.serial_no && <Typography variant="body2" color="text.secondary" className="tower-detail">{`Serial · ${tower?.serial_with_id}`}</Typography>}
			{tower.isDefault && <Chip label="Default" variant="outlined" color="primary" size="small" className="tower-default"/>}
		</div>
		<div className="toggle">{!show_toggle_switch ? null :
			<FormControlLabel
				value="start"
				disabled={freeze_toggle_tower}
				control={<Switch 
					color="primary" 
					checked={checked}
					defaultChecked={tower?.access_to_24h}
					onChange={(e:any) => toggle_tower(e.target.checked)} 
					/>}
				label={<Typography variant="body2" color="primary">24H</Typography>}
				labelPlacement="top"
			/>}
		</div>
	</div>)
}

export default function Search({ ...props }) {
	const head = <Helmet>
		<title>Wand - Search</title>
	</Helmet>

	const logFeat = useLogFeatureInfo()
	const history = useHistory()
	const [searchTerm, setSearchTerm] = useState({ text:'', exact:false })
	const [rootStyle, setRootStyle] = useState<any>({ '--ios-keyboard-offset':'0px' })

	const { loading, data, error } = useTowers({ user:true, latestData:true })
	const user_op = useUser()
	const plan = user_op?.data?.plan?.name

	const [toggle_towers, toggle_towers_op] = useToggleTowers({
		onCompleted:() => {
			toast.show('Toggled towers successfully updated.')
		},
		on_error:(err:any) => {
			console.error(err)
			toast.show('Oops, an error happened on our end. Please try again later.', { error:true, closeText:'close' })
		}
	})
	const centroid = { lat:data?.currentLocation?.latitude, lon:data?.currentLocation?.longitude }

	const toast = new Toast(...useIonToast(), { duration:5000 })
	const is_pro = plan == 'professional'
	const is_org = plan == 'organisation' || plan == 'organization'
	const is_free = !is_pro && !is_org
	const tower_24h_limit = is_free ? 0 : is_pro ? 10 : 10000000000
	const upgrade_message = plan == 'Professional'
		? `Your current plan is '${plan}'. This plan can manage up to 10 towers at a time. To manage more than 10 towers at a time, please upgrade to the 'Organisation' plan.`
		: `Your current plan is 'Free'. This plan can manage up to 3 towers at a time. To manage more than 3 towers at a time, please upgrade to the 'Professional' or 'Organisation' plan.`

	const [show_modal, dismiss_modal] = useIonModal(UpgradePopup, {
		history,
		message: upgrade_message,
		onCancel: () => {
			dismiss_modal()
		},
		on_upgrade:() => {
			dismiss_modal()
			history.push(SETTINGS_UPGRADE_PLAN_PATH)
		}
	})

	useEffect(() => {
		// if (OS == IOS) {
			const adjustTowerList = () => {
				if (window?.visualViewport?.height) {
					const el = document.querySelector('div.tower-items')
					const towerListPosY = el ? el.getBoundingClientRect().y : null
					if (towerListPosY !== null) {
						const towerListHeight = window?.visualViewport?.height - towerListPosY
						if (towerListHeight < 0)
							setRootStyle({ '--ios-keyboard-offset': `${230-towerListHeight}px` })
						else
							setRootStyle({ '--ios-keyboard-offset': `0px` })
					}
				}
			}

			if (window.visualViewport)
				window.visualViewport.addEventListener('resize', adjustTowerList)

			return () => {
				if (window.visualViewport)
					window.visualViewport.removeEventListener('resize', adjustTowerList)
			}
		// }
	},[])

	const authed = useAuth()
	if (authed === null)
		return null

	const onTowerClick = (tower:any) => {
		setSearchTerm({
			text: tower.name,
			exact: true
		})
	}

	const onTowerSelect = (tower:any) => {
		if (!tower?.disabled) {
			logFeat({ 
				title:'switch_tower', 
				content:tower.name
			})
			history.push(HOME_PATH, { tower })
		} else
			show_modal()
	}

	const onChange = (text:any) => {
		setSearchTerm({
			text: text||'',
			exact: false
		})
	}

	const toggle_tower = (id:any, toggle:any, set_switch:any) => {
		if (data?.towers && data.towers.length) {
			const toggled_tower_ids = data.towers.reduce((acc:any, t:any) => {
				if (t.id == id) {
					if (toggle) 
						acc.push(t.id)
				} else if (t.access_to_24h)
					acc.push(t.id)

				return acc
			}, [])

			if (toggled_tower_ids.length > tower_24h_limit) {
				set_switch(false)
				toast.show('Max. 24h towers exceeded. Please upgrade or unselect towers.', { error:true, closeText:'close' })
			} else {
				set_switch(toggle)
				toggle_towers(toggled_tower_ids)
			}

		}
	}

	const absent_color = get_pin_color('absent')
	const absent_dark_color = get_pin_color('absent', true)
	const present_color = get_pin_color('present')
	const present_dark_color = get_pin_color('present', true)
	const orange_color = get_pin_color('orange')
	const orange_dark_color = get_pin_color('orange', true)
	const disabled_color = get_pin_color('disabled')
	const disabled_dark_color = get_pin_color('disabled', true)

	return (
		<IonPage>
			{head}
			<Container style={rootStyle}>
				<div className="tower-list-desktop">
					<div className="search-input">
						<SearchField 
							value={searchTerm} 
							onChange={onChange} 
							onClear={() => setSearchTerm({ text: '', exact: false })}
							onBack={() => history.push(HOME_PATH)}/>
						<div className="legend">
							<div className="legend-item" style={{ '--pin-color':absent_color, '--pin-border-color':absent_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">Absent</div>
								</div>
							</div>
							<div className="legend-item" style={{ '--pin-color':orange_color, '--pin-border-color':orange_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">T° Inversion</div>
								</div>
							</div>
							<div className="legend-item" style={{ '--pin-color':present_color, '--pin-border-color':present_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">Present</div>
								</div>
							</div>
							<div className="legend-item" style={{ '--pin-color':disabled_color, '--pin-border-color':disabled_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">Offline</div>
								</div>
							</div>
						</div>
					</div>
					{loading ?
					<div className="towers-loading">
						<CircularProgress/>
					</div> : error ? 
					<Alert 
						severity="error"
						sx={{ marginLeft: '8px', marginRight: '8px' }}>
						{error?.message||'Oops, something went wrong on our side!'}
					</Alert> :
					<List className="tower-items">{filterTowers(data?.towers, searchTerm).map((tower:any) => { return (
						<Fragment key={tower.id}>
							<ListItem disablePadding>
								<ListItemButton sx={{ width:'100%' }}>
									<TowerDetails 
										key={`${tower.id}-${tower.access_to_24h}`}
										tower={tower} 
										show_toggle_switch={!is_free}
										toggle_tower={toggle_tower}
										freeze_toggle_tower={toggle_towers_op.loading}
										on_click={() => onTowerSelect(tower)}/>
								</ListItemButton>
							</ListItem>
							<Divider/>
						</Fragment>)})}
						<div style={{ width:'100%', height:'100px' }}></div>
					</List>}
				</div>
				<div className={`tower-list-mobile${(data?.towers||[])[0]?.distance === null ? ' short-cards' : ''}`}>
					<div className="search-input">
						<SearchField 
							value={searchTerm} 
							onChange={onChange} 
							onClear={() => setSearchTerm({ text: '', exact: false })}
							onBack={() => history.push(HOME_PATH)}/>
						<div className="legend">
							<div className="legend-item" style={{ '--pin-color':absent_color, '--pin-border-color':absent_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">Absent</div>
								</div>
							</div>
							<div className="legend-item" style={{ '--pin-color':orange_color, '--pin-border-color':orange_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">T° Inversion</div>
								</div>
							</div>
							<div className="legend-item" style={{ '--pin-color':present_color, '--pin-border-color':present_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">Present</div>
								</div>
							</div>
							<div className="legend-item" style={{ '--pin-color':disabled_color, '--pin-border-color':disabled_dark_color }}>
								<div className="legend-container">
									<div className="swatch"></div>
									<div className="swatch-label">Offline</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tower-items">
						<div style={{ display:'flex', height:TOWER_HEIGHT_PX }}>
							<div className="tower-left-gutter"></div>{filterTowers(data?.towers, searchTerm).map((tower:any) => { return (
							<div 
								key={tower.id} 
								className={`tower-card${tower.distance === null ? ' short-card' : ''}`}>
								<TowerDetails 
									key={`${tower.id}-${tower.access_to_24h}`}
									tower={tower} 
									show_toggle_switch={!is_free}
									toggle_tower={toggle_tower}
									freeze_toggle_tower={toggle_towers_op.loading}
									on_click={() => onTowerSelect(tower)}/>
							</div>)})}
						</div>
					</div>
				</div>
				<div className="googlep-map">
					<GoogleMap 
						center={centroid}
						zoom={6}
						towers={data?.towers}
						onTowerClick={onTowerClick}
						currentLoc={centroid}
						/>
				</div>
			</Container>
		</IonPage>)
}





