import { useEffect, useState, Fragment } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { IonContent, IonPage, useIonModal, useIonToast } from '@ionic/react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import LogoutIcon from '@mui/icons-material/Logout'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { grey } from '@mui/material/colors'
// import IconButton, { IconButtonProps } from '@mui/material/IconButton'
// import { styled as mdStyled } from '@mui/material/styles'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { logOut, getSessionUser } from '../../auth'
import FormFeedback from '../../components/FormFeedback'
import { TitleHeader } from '../../components/Header'
// import { LOGIN_PATH, NOTIFICATION_TYPES_PATH, PROFILE_PATH, SPRAY_LOG_PATH } from '../../path'
import { LOGIN_PATH, PROFILE_PATH, SPRAY_LOG_PATH, SETTINGS_TEAM_MEMBERS_PATH, SETTINGS_TEAMS_PATH } from '../../path'
import pack from '../../../package.json'
import Toast from '../../utils/Toast'
import { useLogFeatureInfo } from '../../utils/session'
import { useUser } from '../../data/user'
import { useTowers } from '../../data/tower'
import { isInstallable } from '../../utils/browser'
import InstallAppMessage from '../../components/InstallAppMessage'
import SettingsHeader from './SettingsHeader'
import { GUTTER, CONTAINER_PAD, SectionTitle } from './Component'
import { is_plan_on, set_plan_on } from '../../utils/global'
import { useAuth } from '../../auth'

const TERMS_PATH = '/assets/legal/20221212_terms.pdf'
const PRIVACY_POLICY_PATH = 'https://www.goannaag.com.au/privacy-policy-mojave'
const USER_MANUAL = '/assets/manual/20230215_wand_manual.pdf'
const UPGRADE_KEY = 'goanna-wand-upgrade-mode'

export const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const SectionItemDiv = styled.div.attrs(({ info, lastitem, disabled }:{info?:boolean, lastitem?:boolean, disabled?:boolean}) => ({ info, lastitem, disabled }))`
	padding-top: ${CONTAINER_PAD};
	padding-left: ${GUTTER};
	padding-right: ${GUTTER};
	padding-bottom: ${(props:any) => props.lastitem ? '20px' : CONTAINER_PAD};
	display: grid;
	grid-template-areas: 
		'title    icon'
		'subtitle icon';
	grid-template-rows: 24px 24px;
	grid-template-columns: 1fr 60px;

	& .title {
		grid-area: title;
		${(props:any) => !props.disabled ? '' : `color: ${grey[600]};`}
	}

	& .subtitle {
		grid-area: subtitle;
	}

	& .icon {
		grid-area: icon;
		display: flex;
		align-items: center;
		justify-content: center;

		${(props:any) => !props.disabled ? '' : `
		& svg {
			color: ${grey[400]};
		}`}
	}

	${(props:any) => props.disabled || props.info ? '' : `
	cursor: pointer;
	&:hover {
		background-color: ${grey[100]};
	}`
	}
`

export const SectionDivider = ({ ...props }) => {
	const { top } = props
	return (
		<Box sx={{ margin: `${top || '0px'} -${CONTAINER_PAD} 0px -${CONTAINER_PAD}` }}>
			<Divider/>
		</Box>
	)
}

export const SectionItem = ({ ...props }) => {
	const onClick = props.onClick || (() => null)

	return (
		<SectionItemDiv onClick={onClick} info={props.info} disabled={props.disabled} lastitem={props.lastitem} >
			<Typography variant="body1" sx={{ fontWeight:500 }} className="title">{props.children}</Typography>
			{props.description && 
				<Typography variant="caption" color="text.secondary" className="subtitle">{props.description}</Typography>
			}
			{props.icon && 
				<Box className="icon">{props.icon}</Box>}
			{props.note && 
				<Typography variant="caption" color="text.secondary" sx={{ opacity:0.9, fontStyle:'italic', marginTop:'8px' }}>
					{props.note}
				</Typography>}
		</SectionItemDiv>
	)
}

const Settings = ({ ...props }) => {
	const head = <Helmet>
		<title>Wand - Settings</title>
	</Helmet>

	const authed = useAuth()

	const location = useLocation<any>()
	const searchParams = new URLSearchParams(location.search)
	const p_on = searchParams.get('plan_on')

	if (p_on === 'true' || p_on === 'false')
		set_plan_on(p_on === 'true')

	const plan_on = is_plan_on()

	const theme = useTheme()
	const cssVars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}

	const { yes:canInstalled, alreadyInstalled } = isInstallable(props.deferredPrompt)
	const disableAddToHomescreen = !canInstalled && alreadyInstalled
	const history = useHistory()
	// Delete or uncomment this
	// const user_op = {
	// 	loading:false,
	// 	data: { users:{ data:[{
	// 		first_name:'Dummy Mark', 
	// 		last_name:'Dummy Smith', 
	// 		email:'dummy@test.com'
	// 	}]}}
	// }
	const user_op = useUser()
	const towers_op = useTowers()

	const [email, setEmail] = useState('')
	const [fullname, setFullname] = useState('')
	const [plan, set_plan] = useState('Free')
	const logFeat = useLogFeatureInfo()

	const orgs = [{ name:'My company' }]

	const toast = new Toast(...useIonToast(), { duration:5000 })

	const [presentFeedback, dismissFeedback] = useIonModal(FormFeedback, {
		onCancel: () => {
			dismissFeedback()
		},
		onConfirm: () => {
			toast.show('Feedback sent')
			dismissFeedback()
		}
	})

	const [showInstallAppMessage, closeInstallAppMessage] = useIonModal(InstallAppMessage, {
		onCancel: () => {
			closeInstallAppMessage()
		}
	})

	useEffect(() => {
		if (!user_op.loading) {
			const u = user_op.data
			if (u) {
				const { full_name, email, plan } = u
				if (full_name) 
					setFullname(full_name)
				else
					setFullname(email)

				setEmail(email)
				set_plan(plan?.name)
			}
		}
	}, [location, user_op]) // Listening to 'location' guarantees the this code is executed each time the navigation changes via the history.push API

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then(async (resp: any) => setRedirect(resp && resp[0] ? true : false), []))
	// const [toggle_towers] = useToggleTowers({
	// 	onCompleted:() => {
	// 		console.log('Towers toggled')
	// 	},
	// 	onError:(error:any) => {
	// 		console.error('Failed to toggle towers')
	// 		console.error(error)
	// 	}
	// })
	useEffect(() => {
		if (plan_on && authed && (towers_op?.data?.towers||[]).length) {
			const plan = localStorage.getItem(UPGRADE_KEY)
			const is_pro = plan === 'professional'
			const is_org = plan === 'organization'
			if (is_pro || is_org) {
				// accept(invite_id)
				// const tower_ids = is_pro 
				// 	? towers_op.data.towers.slice(0,10).map(x => x.id)
				// 	: towers_op.data.towers.map(x => x.id)

				// toggle_towers(tower_ids)
				localStorage.setItem(UPGRADE_KEY, '')
			}
		}
	}, [authed, towers_op?.data?.towers, plan_on])

	const signOut = async () => {
		await logOut()
		window.location.href = LOGIN_PATH
	}

	const goToPrivacyPolicy = () => {
		window.open(PRIVACY_POLICY_PATH, '_blank')
		// history.push(PRIVACY_POLICY_PATH, { origin:'settings' })
	}

	const goToTerms = () => {
		window.open(TERMS_PATH, '_blank')
		// history.push(TERMS_PATH, { origin:'settings' })
	}

	const goToManual = () => {
		window.open(USER_MANUAL, '_blank')
		// history.push(TERMS_PATH, { origin:'settings' })
	}

	const goToSprayLogs = () => {
		logFeat({ title:'list_spray_log' })
		history.push(SPRAY_LOG_PATH)
	}

	const addToHomeScreen = async () => {
		if (canInstalled) {
			// Show the install prompt
			props.deferredPrompt.prompt()
			const { outcome } = await props.deferredPrompt.userChoice
			if (outcome != 'dismissed')
				props.deferredPrompt = null
		} else {
			showInstallAppMessage()
		}
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div style={cssVars}>
			<IonPage>
				{head}
				<TitleHeader title="Settings" onBack={() => history.push('/')} />
				<IonContent fullscreen>
					<SettingsHeader orgs={orgs} fullname={fullname} plan={plan}/>
					<SettingsContainer>
						<SectionDivider top="0px"/>

						<SectionTitle>Profile</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Edit your profile details" 
							onClick={() => history.push(PROFILE_PATH)} 
							clickable
							icon={<ArrowForwardIosIcon color="disabled"/>}>
							Profile details
						</SectionItem>

						<SectionDivider/>{!plan_on ? null :<Fragment>

						<SectionTitle>Team</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Manage your team" 
							onClick={() => history.push(SETTINGS_TEAM_MEMBERS_PATH)} 
							clickable
							icon={<ArrowForwardIosIcon color="disabled"/>}>
							Members
						</SectionItem>
						<SectionItem 
							lastitem={true}
							description="List of the teams you have joined" 
							onClick={() => history.push(SETTINGS_TEAMS_PATH)} 
							clickable
							icon={<ArrowForwardIosIcon color="disabled"/>}>
							Teams you have joined
						</SectionItem>

						<SectionDivider/></Fragment>}

						{/*This set of settings is for Phase II*/}
						{/*<SectionTitle>Account</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="View your plan"
							note="NOTE: This plan is used for your personal account. If you manage organizations, each of them use a different plan."
							onClick={editProfile} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Free
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>Team</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Manage your organization's members"
							note="WARNING: Team members can only be added in organizations. Clicking on this tile will prompt to create an organization first (free)."
							onClick={editProfile} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Members (0)
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>Payments</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Manage your payment methods"
							onClick={editProfile} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Payment methods
						</SectionItem>

						<SectionDivider/>*/}

						{/*<SectionTitle>Notifications</SectionTitle>
						<SectionItem 
							lastitem={true}
							description="Choose which notifications to receive"
							onClick={() => history.push(NOTIFICATION_TYPES_PATH)} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Notifications
						</SectionItem>

						<SectionDivider/>*/}

						<SectionTitle>Logs</SectionTitle>
						<SectionItem 	
							lastitem={true}
							description="Search the spray log"
							onClick={goToSprayLogs} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Spray log
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>About</SectionTitle>
						<SectionItem 
							description="Read more in our user manual"
							onClick={goToManual} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Help
						</SectionItem>
						<SectionItem 
							description="Contact us or send us feedback"
							onClick={presentFeedback} 
							clickable>
							Contact us
						</SectionItem>
						<SectionItem 
							description={pack.version}
							info={true}>
							Version
						</SectionItem>
						<SectionItem 
							description="All the things you need to know"
							onClick={goToTerms} 
							clickable
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Terms and conditions
						</SectionItem>
						<SectionItem 
							description="Important for both of us"
							onClick={goToPrivacyPolicy} 
							clickable
							lastitem={true}
							icon={<ArrowForwardIosIcon  color="disabled"/>}>
							Privacy policy
						</SectionItem>

						<SectionDivider/>

						<SectionTitle>Others</SectionTitle>
						<SectionItem 
							disabled={disableAddToHomescreen}
							description={`${disableAddToHomescreen ? 'This app is already installed' : 'Install this app'} on your device`}
							onClick={disableAddToHomescreen ? () => null : addToHomeScreen} 
							clickable
							icon={<ControlPointIcon color="primary"/>}>
							Add to homescreen
						</SectionItem>
						<SectionItem 
							lastitem={true}
							description={`You are logged in${email ? ` as ${email}` : ''}`}
							onClick={signOut} 
							clickable
							icon={<LogoutIcon color="primary"/>}>
							Log out
						</SectionItem>
						<Box sx={{ width:'100%', height:'80px' }}></Box>
					</SettingsContainer>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Settings
