/*
	WARNING REFERENCES (this code contains snippets that do not seem to do anything functional. Instead, they fix the following warnings):
		1. MUI: The `value` provided to the Tabs component is invalid. The Tab with this `value` ("0") is not part of the document layout.
 */

import pack from '../../package.json'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { IonContent, IonHeader, IonPage, useIonToast, useIonModal } from '@ionic/react'
import Toast from '../utils/Toast'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Fab from '@mui/material/Fab'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import LensBlurIcon from '@mui/icons-material/LensBlur'
import DownloadIcon from '@mui/icons-material/Download'
import IconButton from '@mui/material/IconButton'
import { useAuth } from '../auth'
import { SEARCH_PATH, SETTINGS_UPGRADE_PLAN_PATH } from '../path'
import { SearchBar } from '../components/SearchBar'
import FormSpray from '../components/FormSpray'
import FormDownloadHistory from '../components/FormDownloadHistory'
import ToggleTowerPopup from '../components/ToggleTowerPopup'
import ConfirmAcceptInvite from '../components/ConfirmAcceptInvite'
import UpgradePopup from '../components/UpgradePopup'
import DataCard from '../components/DataCard'
import { getBrowserDetails } from '../utils/browser'
// import Ad from '../components/Ad'
import RefreshSpinner, { resetRefreshSpinner, moveRefreshSpinner } from '../components/RefreshSpinner'
import History from '../components/History'
import { startPolling } from '../utils/graphql'
import { useTowers } from '../data/tower'
import './Weather.css'
import { useAcceptInvite } from '../data/team'

const DISABLE_GEO_FENCING = process.env.REACT_APP_DISABLE_GEO_FENCING == 'true' 
const ONE_MINUTE_IN_MS = 60*1000
const ONE_HOUR_IN_MS = 60*ONE_MINUTE_IN_MS
const ONE_DAY_IN_MS = 24*ONE_HOUR_IN_MS
const TOWERS_POLL_INTERVAL_MS = 10*ONE_MINUTE_IN_MS // 10 minutes
const NOTIFICATIONS_POLL_INTERVAL_MS = 3*ONE_MINUTE_IN_MS // 3 minutes
const ACCEPT_KEY = 'goanna-wand-accept-id'

const GET_UNREAD_NOTIFICATIONS = gql`
query get_unread_notifications {
	notification_unread_count{
		id
		count
	}
}`

const GET_LATEST_TOWER_DATA = gql`
query get_latest_data($tower_id: ID, $step: Step24HourForecastEnum) {
	tower_readings (where: { tower: { id:$tower_id } } latest:true) {
		data {
			id
			date_utc
			deltat_1
			temp_1
			tempdiff_h10_h1_avg
			ws_2
			ws_10
			wd_10_label
			wd_cv2_label
			windgust_2
			solardown_10min
			rain_acc
			rain_prev_24h
			hazard_status
			hazard
			nowcast
			humidity
			next_24_hours(step: $step) {
				start_date
				end_date
				unsafe
			}
		} 
	}
}`

const GET_LATEST_TOWER_RAIN_ACC = gql`
query get_latest_rain_acc($tower_id: ID) {
	tower_latest_rain_accumulation (where: { tower: { id:$tower_id } }) {
		rain_acc
		rain_prev_24h
	}
}`

const UPDATE_APP_VERSION = gql`
mutation user_update_app_version($version:String!, $device:String) {
	user_update_app_version(
		version: $version
		device: $device
	) {
		message
	}
}`

const _void = (e?:any) => e

const cache_activity_update:any = {}

const Weather: React.FC = () => {
	const head = <Helmet>
		<title>Wand - Towers</title>
	</Helmet>

	const authed = useAuth()

	const history = useHistory()
	const location = useLocation<any>()

	const theme = useTheme()
	const containerRef = useRef(null)
	const downloadRef = useRef<HTMLAnchorElement>(null)
	const cssVars:any = {
		'--padding': '15px',
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}
	const [towerName, setTowerName] = useState('')
	const [accept_invite_error, set_accept_invite_error] = useState('')
	const [selectedTower, setSelectedTower] = useState<any>({})
	const [tabValue, setTabValue] = useState(1)
	const [newMessages, setNewMessages] = useState(0)
	const [refreshing, setRefreshing] = useState(false)
	const [fieldDef, setFieldDef] = useState<any>({})

	const unreadNotificationsOp = useQuery(GET_UNREAD_NOTIFICATIONS, {
		onCompleted(data:any) {
			setNewMessages(data?.notification_unread_count?.count||0)
		}
	})
	const [getLatestData, latestDataOp] = useLazyQuery(GET_LATEST_TOWER_DATA, {
		notifyOnNetworkStatusChange: true,
		onCompleted() {
			setRefreshing(false)
		},
		onError() {
			setRefreshing(false)
		}
	})
	const [getLatestRainAcc, latestRainAccOp] = useLazyQuery(GET_LATEST_TOWER_RAIN_ACC, {
		notifyOnNetworkStatusChange: true,
		// onCompleted() {
		// 	setRefreshing(false)
		// },
		onError(err) {
			console.error(err)
			// setRefreshing(false)
		}
	})

	const [update_app_version] = useMutation(UPDATE_APP_VERSION)
	if (!cache_activity_update.refresh_time || cache_activity_update.refresh_time < Date.now()) {
		try {
			cache_activity_update.refresh_time = Date.now() + ONE_DAY_IN_MS
			const version = pack.version
			const device = JSON.stringify(getBrowserDetails()||{})
			update_app_version({
				variables:{
					version,
					device
				}
			})
		} catch(err:any) {
			console.warn(`Failed to log current app version. Details: ${err.message}`)
		}
	}

	// Makes sure that the cached is automatically refreshed after 'TOWERS_POLL_INTERVAL_MS' milliseconds
	// NOTES:	Though 'useQuery' defines a 'pollInterval' option, it does not work. That's why we have to resort to use 
	//			'startPolling' and 'stopPolling'.
	useEffect(() => startPolling(latestDataOp, TOWERS_POLL_INTERVAL_MS), [latestDataOp])
	useEffect(() => startPolling(latestRainAccOp, TOWERS_POLL_INTERVAL_MS), [latestRainAccOp])
	useEffect(() => startPolling(unreadNotificationsOp, NOTIFICATIONS_POLL_INTERVAL_MS), [unreadNotificationsOp])

	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])
	const [showSprayModal, hideSprayModal] = useIonModal(FormSpray, {
		towerId: selectedTower?.id,
		towerName: selectedTower?.name,
		readingId: (latestDataOp?.data?.tower_readings?.data||[])[0]?.id,
		onCancel: () => {
			hideSprayModal()
		},
		onConfirm: () => {
			toast.show('Spray event successfully recorded')
			hideSprayModal()
		}
	})
	const [showDownloadHistoryModal, hideDownloadHistoryModal] = useIonModal(FormDownloadHistory, {
		towerId: selectedTower?.id,
		towerName: selectedTower?.name,
		onCancel: () => {
			hideDownloadHistoryModal()
		},
		onDownloaded: () => {
			toast.show('Download successful')
			hideDownloadHistoryModal()
		}
	})
	const [show_accept_invite, hide_accept_invite] = useIonModal(ConfirmAcceptInvite, {
		error:accept_invite_error,
		on_cancel: () => {
			hide_accept_invite()
		}
	})
	const [show_toggle_tower_modal, hide_toggle_tower_modal] = useIonModal(ToggleTowerPopup, {
		tower_id: selectedTower?.id,
		onCancel: () => {
			hide_toggle_tower_modal()
		},
		on_toggled: () => {
			toast.show('Tower successfully toggled')
			hide_toggle_tower_modal()
			refetch_towers()
			// setSelectedTower({ ...selectedTower, access_to_24h:true })
		},
		upgrade: () => {
			hide_toggle_tower_modal()
			history.push(SETTINGS_UPGRADE_PLAN_PATH)
		}
	})

	const accept = useAcceptInvite({
		onCompleted() {
			show_accept_invite()
		},
		onError(error:any) {
			const err_msg = `${error?.message}`
			console.error(error)
			if (err_msg.indexOf('not found') >= 0 || err_msg.indexOf('cannot be accepted anymore') >= 0)
				set_accept_invite_error('This invitation has expired or has been deactivated.')
			else
				set_accept_invite_error('Oops, an error happened on our end. Please try again later.')
			show_accept_invite()
		}
	})

	const [show_upgrade_modal, dismiss_upgrade_modal] = useIonModal(UpgradePopup, {
		history,
		onCancel: () => {
			dismiss_upgrade_modal()
		},
		on_upgrade:() => {
			dismiss_upgrade_modal()
			history.push(SETTINGS_UPGRADE_PLAN_PATH)
		}
	})

	const onSearchClick = () => {
		window.location.href = SEARCH_PATH
		// history.push(SEARCH_PATH)
	}

	const selectTower = useCallback((tower:any) => {
		setSelectedTower(tower)
		setTowerName(tower.name||'')
		getLatestData({
			variables:{
				tower_id: tower?.id,
				step: process.env.REACT_APP_FORECAST_24HOURS_STEP // Valid values: ONE_HOUR, TWO_HOURS, THREE_HOURS, FOUR_HOURS, SIX_HOURS, TWELVE_HOURS
			}
		})
		if (tower.serial_no)
			getLatestRainAcc({ variables: { tower_id:tower.id }})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { loading:loadingTowersAndUser, refetch:refetch_towers } = useTowers({ 
		user:true,
		onCompleted(data:any) {
			const selected_tower_exists = selectedTower?.id
			if (!location?.state?.tower && (!selectedTower || !selected_tower_exists)) {
				if (data?.defaultTower)
					selectTower(data?.defaultTower)	
				else if (data?.towers && data?.towers[0])
					selectTower(data?.towers[0])	
			} else if (selected_tower_exists) {
				const refreshed_selected_tower = (data?.towers||[]).find(x => x.id == selectedTower.id)
				if (refreshed_selected_tower && refreshed_selected_tower.access_to_24h != selectedTower.access_to_24h)
					selectTower(refreshed_selected_tower)
			}
		},
		onError(error:any) {
			console.error(error.message)
			toast.show('Oops, an error occured on our side. We could not load the data now. Try again later.', { error:true, closeText:'close' })
		}
	})


	// Set default tower
	useEffect(() => {
		if (location?.state?.tower)
			selectTower(location?.state?.tower)
	}, [location?.state?.tower, selectTower])

	const onFabClick = (mode:string) => {
		if (mode == 'spray')
			showSprayModal()
		else {
			if (!selectedTower.access_to_24h)
				show_upgrade_modal()
			else 
				showDownloadHistoryModal()
		}
	}

	// Refresh wheel logic
	useEffect(() => {
		if (selectedTower.id && !refreshing) {
			let latestClientY = 0, startClientY = 0
			const allowedToRefresh = () => tabValue > 0
			const startTrack = (e:any) => {
				if (allowedToRefresh() && !refreshing && e && e.touches && e.touches[0]) 
					startClientY = e.touches[0].clientY || 0
			}
			const endTrack = () => {
				const refreshCurrentTower = resetRefreshSpinner()
				if (allowedToRefresh() && !refreshing && refreshCurrentTower) {
					latestDataOp.refetch()
					latestRainAccOp.refetch()
					setRefreshing(true)
				}
			}
			const trackMove = (e:any) => {
				if (allowedToRefresh() && !refreshing && e && e.touches && e.touches[0]) { // If a tower has been selected
					const currentClientY = e.touches[0].clientY || 0
					const diff = currentClientY - latestClientY
					if (diff > 0) { // If the finger is sliding down
						const el:any = containerRef?.current
						try {
							const offsetTop = 108 // Had to be hardcoded cause Safari and iOS don't support el?.offsetTop
							if (el?.getBoundingClientRect) {
								const top = (el?.getBoundingClientRect() || {}).top
								const topReached = top >= offsetTop
								if (topReached) // If the top has been reached
									moveRefreshSpinner(currentClientY - startClientY)
							}
						} catch(err) {
							_void(err)
						}
					}

					latestClientY = currentClientY
				}
			}

			document.addEventListener('touchstart', startTrack, false)
			document.addEventListener('touchend', endTrack, false)
			document.addEventListener('touchmove', trackMove, false)

			return () => {
				document.removeEventListener('touchstart', startTrack)
				document.removeEventListener('touchend', endTrack)
				document.removeEventListener('touchmove', trackMove)
			}
		}
	}, [selectedTower, refreshing, tabValue, latestDataOp, latestRainAccOp])

	useEffect(() => {
		if (refreshing && selectedTower.id) {
			setTimeout(() => { // give time to the UI to update the card before hiding the refresh spin. Otherwise, it gives the user the impression they can refresh again though the UI is still in the process of updating the card
				setRefreshing(false)
				resetRefreshSpinner(true)
			}, 1000)
		}
	}, [refreshing, selectedTower.id])

	useEffect(() => {
		if (authed) {
			const invite_id = localStorage.getItem(ACCEPT_KEY)
			if (invite_id) {
				accept(invite_id)
				localStorage.setItem(ACCEPT_KEY, '')
			}
		}
	}, [authed, accept])


	const a11yProps = (index: number) => {
		return {
			id: `full-width-tab-${index}`,
			value: index,
			'aria-controls': `full-width-tabpanel-${index}`
		}
	}

	const handleTabChange = (event: any, newValue: number) => {
		setTabValue(newValue)
	}

	const showFieldDef = (field:string, description:string) => {
		setFieldDef({
			show: true,
			field,
			description
		})
	}

	const onRefresh = () => {
		setRefreshing(true)
		latestDataOp.refetch()
		latestRainAccOp.refetch()
	}

	const mergeData = (towerData:any, rainData:any) => {
		if (towerData && rainData)
			return { ...towerData, rain_acc:rainData.rain_acc, rain_prev_24h:rainData.rain_prev_24h }
		else
			return towerData
	}

	const tabs = ['History', 'Now']

	if (authed === null || !authed)
		return null

	return (
		<div style={cssVars}>
			<IonPage>
				{head}
				<IonHeader className="weather-header">
					<div className="linear-bg toolbar-header">
						<SearchBar 
							value={towerName}
							onSearchMode={onSearchClick}
							newMessages={newMessages}
							disabled={loadingTowersAndUser || latestDataOp.loading}/>
					</div>
					<AppBar position="static" className="app-bar linear-bg">
						<Tabs
							className="custom-tabs"
							value={tabValue}
							onChange={handleTabChange}
							TabIndicatorProps={{style: {background:'white'}}}
							textColor="inherit"
							variant="fullWidth"
							aria-label="full width tabs example"
							sx={{height: "20px"}}
							>{tabs.map((tabName:string, idx:number) => {
								return <Tab label={tabName} key={idx} {...a11yProps(idx)} className="custom-tab" />
							})}
						</Tabs>
					</AppBar>
				</IonHeader>
				<IonContent fullscreen style={{ '--background': '#ebebeb' }}>
					<div ref={containerRef} className="content-container">
						{<Box sx={{ display: 'flex', justifyContent:'center' }}>
							{loadingTowersAndUser || (!refreshing && latestDataOp.loading) ?
							<div style={{ marginTop:'50px' }}>
								<CircularProgress/>
							</div> : selectedTower.id ? (tabValue > 0 ? 
							<div className="content-section content-data-card">
								<div className="side-area left-area"></div>
								<div className="side-area right-area">
									<div className="ad-box">
										{/*<Ad/>*/}
									</div>
								</div>
								<DataCard 
									mode="now"
									towerId={selectedTower.id}
									towerName={selectedTower.name}
									towerTz={selectedTower?.coord?.tz}
									defaultButton={DISABLE_GEO_FENCING}
									defaultTower={selectedTower.isDefault}
									loading={latestDataOp.loading}
									access_24h={selectedTower.access_to_24h}
									data={mergeData((latestDataOp.data?.tower_readings?.data||[])[0], latestRainAccOp.data?.tower_latest_rain_accumulation)}
									dateSideGutter="35px"
									onRefresh={onRefresh}
									onHelp={showFieldDef}
									open_toggle_modal={show_toggle_tower_modal}
									classes={['data-area']}/>
							</div> : 
							<History 
								towerId={selectedTower.id} 
								towerName={selectedTower.name} 
								tz={selectedTower.coord?.tz}
								access_24h={selectedTower.access_to_24h}/>) : 
							<Typography color="text.secondary" sx={{ paddingTop:'30px' }}>No tower selected</Typography>}
						</Box> }
					</div>
				</IonContent>
				{selectedTower.id && (refreshing || !latestDataOp.loading) &&
				<Fab 
					variant="extended" 
					className="gradient-bg"
					color="primary" 
					aria-label="spray" 
					sx={{ position:'absolute', bottom:'16px', right:'16px' }}
					onClick={() => onFabClick(tabValue > 0 ? 'spray' : 'download')}>
					{tabValue > 0 ? <LensBlurIcon sx={{ mr: 1 }} /> : <DownloadIcon sx={{ mr: 1 }} />}
					{tabValue > 0 ? 'Spray record' : 'Download'}
				</Fab>}
				<Box sx={{ position:'absolute', width:'100%', display: 'flex', justifyContent: 'center' }}>
					<RefreshSpinner spin={refreshing}/>
				</Box>
				<a ref={downloadRef} style={{ visibility:'hidden' }} href="#">download</a>
				<Drawer
					anchor="right"
					open={fieldDef.show}
					onClose={() => setFieldDef({})}
					sx={{
						'& .MuiDrawer-paper': { 
							boxSizing: 'border-box', 
							width: 400 
						},
						'@media (max-width: 768px)': {
							'& .MuiDrawer-paper': { 
								width: '100vw'
							}	
						}
					}}>
					<div className="field-def">
						<div className="close-header">
							<IconButton sx={{ p: '10px', marginRight: '-20px' }} aria-label="back" onClick={() => setFieldDef({})}>
								<CloseIcon/>
							</IconButton>
						</div>
						<Typography variant="h5">{fieldDef.field}</Typography>
						<Typography 
							variant="body1" 
							dangerouslySetInnerHTML={{ __html:fieldDef.description }}
							className="field-description"/>
					</div>	
				</Drawer>
			</IonPage>
		</div>
	)
}

export default Weather
