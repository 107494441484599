import { gql, useQuery } from '@apollo/client'
import CellTowerIcon from '@mui/icons-material/CellTower'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import AirIcon from '@mui/icons-material/Air'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import InsightsIcon from '@mui/icons-material/Insights'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { set_plan_on } from '../utils/global'

const PLANS:any = {
	free: {
		name:'free',
		next:'professional',
		price:0,
		upgrade_ad: {
			name:'Level up',
			message: 'Access more towers, collaborate with team members, gain 24 hours forecast and much more.',
			cta:'Get Professional'
		},
		lower_plans:null,
		limits: [
		{
			icon: CellTowerIcon,
			name:'Tower access',
			description:'3 closest towers only'
		},
		{
			icon: AirIcon,
			name:'Hazardous forecast',
			description:'Up to 2 hours max.'
		},{
			icon: CloudQueueIcon,
			name:'Weather data history',
			description:'Past 48 hours only'
		},{
			icon: PeopleAltIcon,
			name:'Team members',
			description:'0 available'
		}],
		benefits: [{
			icon: TipsAndUpdatesIcon,
			name:'Weather insights',
			description:'Access the latest basic weather readings for all towers.'
		},{
			icon: AirIcon,
			name:'Hazardous forecast',
			description:'Access the hazardous forecast (up to 2 hours) for all towers.'
		},{
			icon: InsightsIcon,
			name:'Historical charts',
			description:'Monitor the past 48 hours of weather readings with charts'
		}]
	},
	professional: {
		name:'professional',
		next:'organization',
		price:249,
		upgrade_ad: {
			name:'Need even more access?',
			message: 'All that is included in Professional + Unlimited access to all towers and collaboration with up to 20 team members.',
			cta:'Get Organisation'
		},
		lower_plans:['free'],
		limits: [
		{
			icon: CellTowerIcon,
			name:'Tower access',
			description:'0 / 10'
		},
		{
			icon: PeopleAltIcon,
			name:'Team members',
			description:'0 / 5'
		},{
			icon: AttachMoneyIcon,
			name:'GoWeather credit',
			description:'AU$0 / AU$500'
		}],
		benefits: [{
			icon: AttachMoneyIcon,
			name:'AU$500 GoWeather credit',
			description:'Get a AU$500 on your next GoWeather purchase.'
		},{
			icon: TipsAndUpdatesIcon,
			name:'Weather insights',
			description:'Access the latest weather readings including extra values such as rain for up to 10 towers located anywhere in Australia.'
		},{
			icon: AirIcon,
			name:'Hazardous forecast',
			description:'Access the hazardous forecast for the next 24 hours for up to 10 towers located anywhere in Australia.'
		},{
			icon: PeopleAltIcon,
			name:'Collaboration',
			description:'Invite up to 5 team members to help you take data-driven actions when it matters the most.'
		},{
			icon: InsightsIcon,
			name:'Historical charts',
			description:'Monitor weather readings at any period in the past with charts and data export.'
		}]
	},
	organization: {
		name:'organization',
		next:null,
		price:null,
		upgrade_ad:null,
		lower_plans:['professional', 'free'],
		limits: [
		{
			icon: CellTowerIcon,
			name:'Tower access',
			description:'0 / Unlimited'
		},
		{
			icon: PeopleAltIcon,
			name:'Team members',
			description:'0 / 20'
		},{
			icon: AttachMoneyIcon,
			name:'GoWeather credit',
			description:'AU$0 / AU$500'
		}],
		benefits: [{
			icon: AttachMoneyIcon,
			name:'AU$500 GoWeather credit',
			description:'Get a AU$500 on your next GoWeather purchase.'
		}, {
			icon: TipsAndUpdatesIcon,
			name:'Weather insights',
			description:'Access the latest weather readings including extra values such as rain for all towers located anywhere in Australia.'
		},{
			icon: AirIcon,
			name:'Hazardous forecast',
			description:'Access hazardous forecast for the next 24 hours for all towers located anywhere in Australia.'
		},{
			icon: PeopleAltIcon,
			name:'Collaboration',
			description:'Invite up to 20 team members to help you take data-driven actions when it matters the most.'
		},{
			icon: InsightsIcon,
			name:'Historical charts',
			description:'Monitor weather readings at any period in the past with charts and data export.'
		}]
	}
}

export const get_plan = (plant_name:any) => PLANS[plant_name] || null

export const GET_USER = `
users(me:true){
	data{
		id
		first_name
		last_name
		email
		default_tower_id
		metadata
		phone {
			code
			number
		}
		organizations {
			plan
			plan_purchase_date
			plan_expiry_date
			plan_auto_renew
			go_weather_credit
		}
	}
}`

export const GET_USER_GQL = gql`
query get_user {
	${GET_USER}
}`

export const UPDATE_FULLNAME = gql`
mutation update_fullname($first_name:String $last_name:String) {
	user_update(first_name: $first_name last_name: $last_name){
		message
		data {
			id
			first_name
			last_name
		}
	}
}`

export const useUser = (options?:any) => {
	const query = useQuery(GET_USER_GQL, {
		onCompleted(data) {
			const u = parse_data(data)
			if (options?.onCompleted)
				options?.onCompleted(u)
		},
		onError(...args) {
			if (options?.onError)
				options?.onError(...args)
		}
	})

	return {
		...query,
		data: parse_data(query.data)
	}
}

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const parse_data = (data:any) => {
	const u = (data?.users?.data||[])[0]
	if (u) {
		const { first_name, last_name, organizations, metadata } = u
		const full_name = [first_name,last_name].filter(x => x).join(' ')
		let plan = 'free', plan_purchase_date, plan_expiry_date, plan_expiry_date_label, go_weather_credit, plan_auto_renew
		if (organizations && organizations.length && organizations[0]?.plan) {
			const org = organizations[0]
			go_weather_credit = org.go_weather_credit
			plan = org.plan
			plan_purchase_date = org.plan_purchase_date
			plan_auto_renew = org.plan_auto_renew
			plan_expiry_date = org.plan_expiry_date
			const t = `${plan}`.trim().toLowerCase()
			plan = t == 'premium' || t == 'professional' ? 'professional' : t == 'organization' ? 'organization' : 'free'
			plan_purchase_date = plan_purchase_date ? new Date(plan_purchase_date) : null
			plan_expiry_date = plan_expiry_date ? new Date(plan_expiry_date) : null

			if (plan_expiry_date) {
				const year = plan_expiry_date.getFullYear()
				const month = plan_expiry_date.getMonth()
				const day = plan_expiry_date.getDate()
				plan_expiry_date_label = `${MONTH_NAMES[month]} ${day}, ${year}`
			}
		}

		const meta = parse_metadata(metadata)
		set_plan_on(meta?.subscription_feature_on)

		return {
			...u,
			full_name,
			plan: PLANS[plan],
			plan_purchase_date,
			plan_expiry_date,
			plan_expiry_date_label,
			plan_auto_renew,
			go_weather_credit,
			meta
		}
	}
}

const parse_metadata = (metadata?:any) => {
	if (!metadata)
		return {}
	try {
		return JSON.parse(metadata)
	} catch(err:any) {
		console.error(`Failed to parse user.metadata: ${err?.message}`)
		return {}
	}
}



