import { useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { IonContent, IonPage, useIonToast } from '@ionic/react'
import Typography from '@mui/material/Typography'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import styled from 'styled-components'
import { ActionHeader } from './Header'
import { useTowers } from '../data/tower'
import { useUser } from '../data/user'
import { useToggleTowers } from '../data/tower'
import ProgressBar from './ProgressBar'
import Toast from '../utils/Toast'

// padding:20px 26px 40px 26px;
const MasterDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

	& > p,
	& > div,
	& > label {
		margin-bottom: 15px;
	}

	& .invite-input > div {
		height: 184px;
	}

	& .it-toolbar {
		display: grid;
		grid-template-rows: 60px;
		grid-template-columns: 100px 1fr 100px;
	}

	& .upgrade {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 6px;
		margin-right:6px;

		& p {
			display: flex;
			justify-content: center;
		}

		& .upgrade-btn {
			cursor: pointer;
			height: 28px;
			width: 96px;
			font-size: 14px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--mu-primary-dark);
			background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			color: white;
			border-radius: 30px;
			font-weight: 500;

			& .upgrade-text {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
			}

			& svg {
				font-size:20px;
				margin-right: 3px;
			}
		}

		&.muted {
			& .upgrade-btn {
				background: #e2ebf3;
				color:var(--mu-primary-dark);
				& svg {
					font-size:16px;
				}
			}
		}

		&.get-pro {
			padding-top: 0px;
			& .upgrade-btn {
				width: 132px;
			}
		}
	}
`

const PlanButton = ({ ...props }) => {
	return (<div className={"upgrade" + (props.muted ? ' muted' : '')}>
		<div className="upgrade-btn" onClick={props.upgrade}>
			<div className="upgrade-text">
				{props.icon}
				{props.label}
			</div>
		</div>
	</div>)
}

const ToggleTowerPopup = ({ ...props }:any) => {
	const tower_id = props.tower_id
	const tower_op = useTowers()
	const user_op = useUser()
	const plan = user_op?.data?.plan?.name
	const { towers_toggled, towers_toggled_ids } = (tower_op?.data?.towers||[]).reduce((acc:any,t:any) => {
		if (t.access_to_24h) {
			acc.towers_toggled++
			acc.towers_toggled_ids.push(t.id)
		}
		return acc
	},{ towers_toggled:0, towers_toggled_ids:[] })

	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])

	const [toggle_towers, toggle_towers_op] = useToggleTowers({
		onCompleted:() => {
			if (props.on_toggled)
				props.on_toggled()
		},
		onError:(error:any) => {
			console.error(error)
			toast.show('Failed to toggle tower. Please contact support for further help.', { error:true, closeText:'close' })
		}
	})


	const is_pro = plan == 'professional'
	let text = null
	let upgrade_to_org = false
	let main_action = 'Toggle'
	if (is_pro) {
		const quota_reached = towers_toggled >= 10
		upgrade_to_org = true
		if (quota_reached) {
			main_action = 'Upgrade'
			text = `You have reached the maximum number of towers for which you can access the 24 hours forecast (10 towers). To toggle the 24 hours forecast for this tower, you can either untoggle other towers or upgrade to the Organisation plan.`
		} else {
			const s = towers_toggled == 1 ? '' : 's'
			text = `You have used ${towers_toggled} tower${s} out of your quotas (10 in Professional plan). Use the "TOGGLE" button in the top-right corner of this popup to toggle the 24 hours forecast on this tower. If you wish to toggle the 24 hours forecast on more than 10 towers, please upgrade to the Organisation plan.`
		}
	} else
		text = `Please use the switch button below to toggle the 24 hours forecast on this tower.`

	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}

	const on_cancel = props.onCancel || (() => null)

	const on_toggled = () => {
		toggle_towers([...(towers_toggled_ids||[]), tower_id])
	}
	const upgrade = () => {
		if (props.upgrade)
			props.upgrade()
	}

	return (
	<IonPage {...props}>
		{toggle_towers_op.loading && <ProgressBar/>}
		<ActionHeader actionName={main_action} onCancel={on_cancel} onConfirm={main_action == 'Upgrade' ? upgrade : on_toggled}/>
		<IonContent className="ion-padding">
			<MasterDiv>
				<div className="upgrade" style={css_vars}>
					<Typography sx={{ marginBottom:'40px' }}>
						{text}
					</Typography>{!upgrade_to_org ? null :
					<PlanButton  
						upgrade={upgrade}
						icon={<UpgradeIcon/>}
						label="Upgrade"/>}
				</div>
			</MasterDiv>
		</IonContent>
	</IonPage>)
}

export default ToggleTowerPopup